<template>
  <div>
    <div class="page-title">原辅料库存管理</div>
    <!-- 选项 -->
    <div style="margin-top: 1rem">
      <el-select
        v-model="料号"
        filterable
        allow-create
        default-first-option
        placeholder="请选择料号"
        clearable
        size="mini"
        style="width:130px"
      >
        <el-option
          v-for="item in 料号列表"
          :key="item.料号"
          :label="item.料号"
          :value="item.料号"
        ></el-option>
      </el-select>
      <el-select
        v-model="GongYingShang"
        filterable
        allow-create
        default-first-option
        placeholder="请选择供应商"
        clearable
        size="mini"
        style="width:130px"
      >
        <el-option
          v-for="item in GongYingShangList"
          :key="item.供应商"
          :label="item.供应商"
          :value="item.供应商"
          filterable
        ></el-option>
      </el-select>
      <el-button size="mini" type="primary" @click="getRawSKUList_" style="margin-left:5px"> 搜索</el-button>
      <el-button size="mini" type="danger" @click="showUpLayer"> 导入库存</el-button>
      <el-button size="mini" type="success" @click="showOutLayer"> 导出库存</el-button>
      <el-button size="mini" type="warning" @click="showAddLayer"> 手动添加</el-button>
    </div>
    <!-- 表格 -->
    <div style="margin-top: 1rem;float: left;width: 100%">
      <el-table
        class="tb-edit"
        :data="rawSKUData"
        border
        style="width: 100%"
        @current-change="handleCurrentChange"
      >
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="分类" label="分类"></el-table-column>
        <el-table-column prop="供应商" label="供应商"></el-table-column>
        <el-table-column prop="品名" label="品名"></el-table-column>
        <el-table-column prop="料号" label="料号"></el-table-column>
        <el-table-column prop="规格" label="规格">
          <template slot-scope="s">
            <span v-html="FormatGG(s.row.规格)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="单位" label="单位"></el-table-column>
        <el-table-column prop="当前库存数" label="当前库存数"></el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleRuku(scope.row)">入库
            </el-button>
            <el-button
              size="mini"
              type="warning"
              plain
              @click="handleBack(scope.row)">退库
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="ShowCk(scope.row)">出库
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="width: 100%;float:left;margin-top: 1rem;">
      <el-pagination
        @current-change="handlePageChange"
        :current-page="whichPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 手动添加弹窗 -->
    <el-dialog
      title="添加原辅料的库存信息"
      :visible.sync="addLayer"
      width="580px"
      :close-on-click-modal="false"
    >
      <el-form :model="rawSKUAddForm">
        <el-form-item label="品名" label-width="110px">
          <el-select v-model="rawSKUAddForm.品名" filterable placeholder="请选择" @change="getCateName_">
            <el-option
              v-for="item in 品名列表"
              :key="item.品名"
              :label="item.品名"
              :value="item.品名">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="料号" label-width="110px">
          <el-input v-model="rawSKUAddForm.料号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分类" label-width="110px">
          <el-input v-model="rawSKUAddForm.分类" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-for="(gg,index) in rawSKUAddForm.规格" :label="gg" label-width="110px">
          <el-input v-model="rawSKUGUiGe[index]"></el-input>
        </el-form-item>
        <el-form-item label="单位" label-width="110px">
          <el-input v-model="rawSKUAddForm.单位" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="供应商" label-width="110px">
          <el-select v-model="rawSKUAddForm.供应商" filterable allow-create default-first-option placeholder="请选择">
            <el-option
              v-for="item in GongYingShangList"
              :key="item.供应商"
              :label="item.供应商"
              :value="item.供应商">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addLayer = false">取 消</el-button>
        <el-button type="primary" @click="rawSKUSubmit">添 加</el-button>
      </div>
    </el-dialog>
    <!-- 入库弹窗 -->
    <el-dialog
      title="原辅料入库"
      :visible.sync="rukuLayer"
      fullscreen
    >
      <el-form>
        <el-form-item label="【分类】" label-width="140px">{{ whichRow.分类 }}</el-form-item>
        <el-form-item label="【品名】" label-width="140px">{{ whichRow.品名 }}</el-form-item>
        <el-form-item label="【料号】" label-width="140px">{{ whichRow.料号 }}</el-form-item>
        <el-form-item label="【单位】" label-width="140px">{{ whichRow.单位 }}</el-form-item>
        <el-form-item label="【规格】" label-width="140px">
          <span v-html="FormatGG(whichRow.规格)"></span>
        </el-form-item>
        <el-form-item label="【供应商】" label-width="140px">{{ whichRow.供应商 }}</el-form-item>
        <el-form-item label="【合同号】" label-width="140px">
          <el-input
            v-model="rukuForm.合同号"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【联系人】" label-width="140px">
          <el-input
            v-model="rukuForm.联系人"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【联系方式】" label-width="140px">
          <el-input
            v-model="rukuForm.联系方式"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【入库日期】" label-width="140px">
          <el-date-picker v-model="rukuForm.日期" size="mini"></el-date-picker>
        </el-form-item>
        <el-form-item label="【入库数量】" label-width="140px">
          <el-input-number size="mini" v-model="rukuForm.入库数量" :min="1" @change="CalcMoney"></el-input-number>
        </el-form-item>
        <el-form-item label="【币种】" label-width="140px">
          <el-select
            v-model="rukuForm.币种"
            size="mini"
            clearable
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in ['人民币','美元']"
              :value="item"
            >{{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="【单价】" label-width="140px">
          <el-input
            v-model="rukuForm.单价"
            style="width:300px"
            size="mini"
            @input="CalcMoney"
          ></el-input>
        </el-form-item>
        <el-form-item label="【总金额】" label-width="140px">
          <el-input
            v-model="rukuForm.总金额"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【付款方式】" label-width="140px">
          <el-select
            v-model="rukuForm.付款方式"
            size="mini"
            clearable
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in ['月结30天','月结60天','月结90天','现结','票到付款']"
              :value="item"
            >{{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="【备注】" label-width="140px">
          <el-input
            v-model="rukuForm.备注"
            style="width:500px"
            :rows="3"
            size="mini"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding:10px 0 0 140px">
        <el-button @click="rukuLayer = false">取 消</el-button>
        <el-button type="primary" @click="DoRuku">入 库</el-button>
      </div>
    </el-dialog>
    <!-- 出库弹窗 -->
    <el-dialog
      title="原辅料出库"
      :visible.sync="ckLayer"
      fullscreen
    >
      <el-form>
        <el-form-item label="【分类】" label-width="140px">{{ whichRow.分类 }}</el-form-item>
        <el-form-item label="【品名】" label-width="140px">{{ whichRow.品名 }}</el-form-item>
        <el-form-item label="【料号】" label-width="140px">{{ whichRow.料号 }}</el-form-item>
        <el-form-item label="【单位】" label-width="140px">{{ whichRow.单位 }}</el-form-item>
        <el-form-item label="【规格】" label-width="140px">
          <span v-html="FormatGG(whichRow.规格)"></span>
        </el-form-item>
        <el-form-item label="【供应商】" label-width="140px">{{ whichRow.供应商 }}</el-form-item>
        <el-form-item label="【领用部门】" label-width="140px">
          <el-input
            v-model="ckForm.领用部门"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【领用人】" label-width="140px">
          <el-input
            v-model="ckForm.领用人"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【领用日期】" label-width="140px">
          <el-date-picker v-model="ckForm.日期" size="mini"></el-date-picker>
        </el-form-item>
        <el-form-item label="【领用数量】" label-width="140px">
          <el-input-number size="mini" v-model="ckForm.数量" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="【备注】" label-width="140px">
          <el-input
            v-model="ckForm.备注"
            style="width:500px"
            :rows="3"
            size="mini"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding:10px 0 0 140px">
        <el-button @click="ckLayer = false">取 消</el-button>
        <el-button type="danger" @click="DoChuku">出 库</el-button>
      </div>
    </el-dialog>
    <!-- 退库弹窗 -->
    <el-dialog
      title="原辅料退库"
      :visible.sync="backLayer"
      fullscreen
    >
      <el-form>
        <el-form-item label="【分类】" label-width="140px">{{ whichRow.分类 }}</el-form-item>
        <el-form-item label="【品名】" label-width="140px">{{ whichRow.品名 }}</el-form-item>
        <el-form-item label="【料号】" label-width="140px">{{ whichRow.料号 }}</el-form-item>
        <el-form-item label="【单位】" label-width="140px">{{ whichRow.单位 }}</el-form-item>
        <el-form-item label="【规格】" label-width="140px">
          <span v-html="FormatGG(whichRow.规格)"></span>
        </el-form-item>
        <el-form-item label="【供应商】" label-width="140px">{{ whichRow.供应商 }}</el-form-item>
        <el-form-item label="【领用部门】" label-width="140px">
          <el-input
            v-model="ckForm.领用部门"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【领用人】" label-width="140px">
          <el-input
            v-model="ckForm.领用人"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【退库日期】" label-width="140px">
          <el-date-picker v-model="ckForm.日期" size="mini"></el-date-picker>
        </el-form-item>
        <el-form-item label="【退库数量】" label-width="140px">
          <el-input-number size="mini" v-model="ckForm.数量" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="【备注】" label-width="140px">
          <el-input
            v-model="ckForm.备注"
            style="width:500px"
            :rows="3"
            size="mini"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding:10px 0 0 140px">
        <el-button @click="backLayer = false">取 消</el-button>
        <el-button type="danger" @click="DoBack">退 库</el-button>
      </div>
    </el-dialog>
    <!-- 导入弹窗 -->
    <el-dialog
      title="原料库存导入"
      fullscreen
      :visible.sync="uploadLayer"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :on-success="function(res){return uploadOver(res)}"
        :before-upload="function(file){ return beforeUpload(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        :action="uploadUrl"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 表格中必须包含列：分类、品名、规格、单位</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <div style="padding:20px 0 0 10px">
        <el-button @click="uploadLayer = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseUrl, baseTitle} from '../configs/squareConfig'
import {
  getRawSKUList,
  addRawSKU,
  getRawSKUNames,
  getRawSKUSupps,
  getCateName,
  rawGetGys,
  rawRukuDo,
  rawChukuDo, rawBackSkuDo, getRawCodeList
} from '../http/api'
import {Loading} from 'element-ui'
import {formRequest} from '../http/formRequest'
import moment from "moment";

let loading
export default {
  name: "RawSKU",
  data() {
    return {
      料号: '',
      品名列表: [],
      GongYingShang: '',
      GongYingShangList: [],
      rawSKUData: [],
      page: 0,
      rawSKUAddForm: {
        品名: '',
        规格: [],
        单位: '',
        分类: '',
        供应商: '',
      },
      rawSKUGUiGe: [],
      rawSKUEditForm: {},
      addLayer: false,
      editLayer: false,
      whichPage: this.page + 1,
      count: 0,
      pageSize: 100,
      maxPage: 1,
      uploadLayer: false,
      uploadUrl: baseUrl + '/raw-sku-upload',
      token: this.$cookies.get('token'),
      rukuLayer: false,
      whichRow: {},
      rukuForm: {},
      ckLayer: false,
      ckForm: {},
      backLayer: false,
    }
  },
  methods: {
    rawSKUSubmit(typ) {
      let rawSKUData = this.rawSKUAddForm
      rawSKUData.规格内容 = this.rawSKUGUiGe
      for (let key in rawSKUData) {
        if (rawSKUData[key] === '') {
          this.$message({
            message: '请输入完整后再提交',
            type: 'warning'
          })
          return
        }
      }
      const token = this.token
      addRawSKU({rawSKUData, token})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              message: '添加成功',
              type: 'success',
            })
            this.getRawSKUList_()
            this.getRawSKUOptions()
            this.addLayer = false
          }
        })
        .catch(err => console.log(err))
    },
    handleRuku(row) {
      rawGetGys({
        token: this.token,
        供应商: row.供应商,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            const rs = res.data.rs
            this.rukuLayer = true
            this.whichRow = row
            this.rukuForm = {
              合同号: '',
              联系人: rs.联系人,
              联系方式: rs.联系方式,
              币种: '人民币',
              单价: '0.00',
              总金额: '0.00',
              日期: moment().format('YYYY-MM-DD'),
              入库数量: 0,
              备注: '',
            }
          }
        })
        .catch(err => console.log(err))
    },
    handleCurrentChange(row, oldRow) {
      // console.log(row)
      // console.log(oldRow)
    },
    handlePageChange(item) {
      console.log(item)
    },
    showAddLayer() {
      this.addLayer = true
    },
    getRawSKUList_() {
      const page = this.page
      const 料号 = this.料号
      const GongYingShang = this.GongYingShang
      const token = this.token
      getRawSKUList({
        page,
        料号,
        GongYingShang,
        token,
      }).then(res => {
        this.rawSKUData = res.data.rs
        this.count = res.data.count
        this.page = res.data.page
        this.pageSize = res.data.pageSize
        this.maxPage = res.data.maxPage
      })
        .catch(err => console.log(err))
    },
    getRawSKUOptions() {
      const token = this.token
      getRawSKUSupps({token})
        .then(res => this.GongYingShangList = res.data.rs)
        .catch(err => console.log(err))
      getRawSKUNames({token})
        .then(res => this.品名列表 = res.data.rs)
        .catch(err => console.log(err))
    },
    showUpLayer() {
      if (this.$refs.upload) this.$refs.upload.clearFiles()
      this.uploadLayer = true
    },
    showOutLayer() {
      const url = baseUrl + '/raw-sku-output'
      let colScreen = {
        料号: this.料号,
        供应商: this.GongYingShang,
      }
      colScreen = JSON.stringify(colScreen)
      formRequest(url, {
        token: this.token,
        colScreen,
      })
    },
    beforeUpload(file) {
      loading = Loading.service({fullscreen: true})
    },
    uploadOver(res) {
      this.$refs.upload.clearFiles()
      loading.close()
      if (res.code !== 'OK') {
        this.$message({
          message: res.msg,
          type: 'error'
        })
      } else {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.getRawList_()
        this.getRawCate_()
        this.uploadLayer = false
      }
    },
    outputRawInfo() {
      const token = this.token
      const chg = this.chg
      const keywords = this.keywords
      let url = baseUrl + '/raw-info-output'
      formRequest(url, {token, chg, keywords})
    },
    getCateName_() {
      const 品名 = this.rawSKUAddForm.品名
      const token = this.token
      getCateName({token, 品名})
        .then(res => {
          if (res.data.code === 'OK') {
            const rs = res.data.rs
            this.rawSKUAddForm.分类 = rs.分类
            this.rawSKUAddForm.品名 = rs.品名
            this.rawSKUAddForm.规格 = JSON.parse(rs.规格)
            this.rawSKUAddForm.单位 = rs.单位
          }
        })
        .catch(err => console.log(err))
    },
    ShowCk(row) {
      this.whichRow = JSON.parse(JSON.stringify(row))
      this.ckLayer = true
      this.ckForm = {
        数量: 1,
        日期: moment().format('YYYY-MM-DD')
      }
    },
    DoRuku() {
      let form = this.rukuForm
      for (let key in form) {
        if (key !== '备注' && !form[key]) {
          this.$message({
            type: 'warning',
            message: `请输入【${key}】`
          })
          return
        }
      }
      rawRukuDo({
        token: this.token,
        row: this.whichRow,
        form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.whichRow = {}
            this.rukuForm = {}
            this.rukuLayer = false
            this.getRawSKUList_()
            this.$message({
              type: 'success',
              message: '入库成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
    DoChuku() {
      let form = this.ckForm
      rawChukuDo({
        token: this.token,
        row: this.whichRow,
        form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.getRawSKUList_()
            this.ckLayer = false
            this.whichRow = {}
            this.rukuForm = {}
            this.$message({
              type: 'success',
              message: '出库成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
    CalcMoney() {
      const form = this.rukuForm
      if (!isNaN(form.入库数量) && !isNaN(form.单价)) {
        this.rukuForm.总金额 = (parseFloat(form.入库数量) * parseFloat(form.单价)).toFixed(2)
      }
    },
    handleBack(row) {
      this.whichRow = row
      this.backLayer = true
      this.ckForm = {
        数量: 1,
        日期: moment().format('YYYY-MM-DD'),
      }
    },
    DoBack() {
      let form = this.ckForm
      rawBackSkuDo({
        token: this.token,
        row: this.whichRow,
        form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.getRawSKUList_()
            this.backLayer = false
            this.whichRow = {}
            this.rukuForm = {}
            this.$message({
              type: 'success',
              message: '退库成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
  },
  computed: {
    FormatGG() {
      return (gg) => {
        if (!gg) return ''
        let arr = []
        gg = JSON.parse(gg)
        for (let key in gg) {
          arr.push(`${key}：${gg[key]}`)
        }
        return arr.join('<br>')
      }
    }
  },
  activated() {
    this.getRawSKUList_()
    this.getRawSKUOptions()
    document.title = '原辅料库存数据管理 - ' + baseTitle
  },
}
</script>

<style scoped>

</style>
